import $ from 'jquery';

import factory from 'utils/factory';

import Module from 'modules/Module';

// import Litepicker from 'litepicker';

// import datepickerFactory from 'jquery-datepicker';

// datepickerFactory($);

// Enable inline svgs in IE
// import svg4everybody from 'svg4everybody';

// svg4everybody();

$(document).ready(() => {
  factory(Module, '.js-module');
});
require('popper.js');
require('bootstrap');
// Enable inline svgs in IE
// import svg4everybody from 'svg4everybody';

// svg4everybody();

$(document).ready(() => {
  factory(Module, '.js-module');
  var menuButton = $('.navbar-toggler');
  var menu = $('#MainNav');

  menuButton.unbind('click');
  menuButton.click(function() {
    // console.log(this);
    menu.slideToggle(200);
  });
});
